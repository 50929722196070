import * as _esDefineProperty2 from "es-define-property";
var _esDefineProperty = _esDefineProperty2;
try {
  if ("default" in _esDefineProperty2) _esDefineProperty = _esDefineProperty2.default;
} catch (e) {}
var exports = {};
var $defineProperty = _esDefineProperty;
var hasPropertyDescriptors = function hasPropertyDescriptors() {
  return !!$defineProperty;
};
hasPropertyDescriptors.hasArrayLengthDefineBug = function hasArrayLengthDefineBug() {
  // node v0.6 has a bug where array lengths can be Set but not Defined
  if (!$defineProperty) {
    return null;
  }
  try {
    return $defineProperty([], "length", {
      value: 1
    }).length !== 1;
  } catch (e) {
    // In Firefox 4-22, defining length on an array throws an exception.
    return true;
  }
};
exports = hasPropertyDescriptors;
export default exports;